/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
var loader='<div class="ajax-loader"><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-56.2 190.3 112.5 16.3" enable-background="new -56.2 190.3 112.5 16.3" xml:space="preserve"><path fill="#1B4687" d="M-56.2,190.3h16.3v16.3h-16.3V190.3z"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/></path><path fill="#8693C2" d="M-32.5,190.3h16.3v16.3h-16.3V190.3z"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/></path><path fill="#1B4687" d="M-9.6,190.3H6.7v16.3H-9.6L-9.6,190.3z"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/></path><path fill="#8693C2" d="M15.1,190.3h16.3v16.3H15.1V190.3z"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.4"/></path><path fill="#1B4687" d="M39.9,190.3h16.3v16.3H39.9V190.3z"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.5"/></path></svg></div>';

        function resizeBlankImages() {
          $('img.lazy').each(function () {
            var originalWidth = $(this).attr('data-width');
            var originalHeight = $(this).attr('data-height');
            var ratioH = originalWidth/originalHeight;
            var width = $('.item').width();
            var height = width/ratioH;
            $(this).css("height",height);
            $(this).parent().css("height",height);
          });
        }
        $(window).on("resize",function(e){
          resizeBlankImages();
        });

        $("div.lazy").lazyload({
          effect : "fadeIn"
        });



        $("img.lazy").each(function(index) {
          var originalWidth = $(this).attr('data-width');
          var originalHeight = $(this).attr('data-height');
          var ratioH = originalWidth/originalHeight;
          var width = $('.item').width();
          var height = width/ratioH;
          $(this).css("height",height);
          $(this).parent().css("height",height);
        });
        $("img.lazy").lazyload({
          effect : "fadeIn",
          failure_limit: Math.max($("img.lazy").length - 1, 0)
        });



       var $grid = $('.grid').isotope({
        itemSelector : '.item',
        layoutMode : 'masonry',
        masonry: {
          columnWidth: '.item',
        }


      });
       
       $grid.on('layoutComplete', function(){
        $(window).trigger("scroll");
      });
      $(".remote-filter").click(function(){
          var post_filter = $(this).attr("data-target");
          var row_filter = $('[data-name="' + post_filter + '"]').attr('value');
          console.log(row_filter);
          $("#SelectFonctions").val(row_filter);
          $('#myTab li a').eq($("#SelectFonctions").val()).tab('show');
         // alert('yo');
        });
$('#SelectFonctions').on('change', function (e) {
    $('#myTab li a').eq($(this).val()).tab('show'); 
});
$.ajaxSetup({cache:false});
$(".post-row.post-type-carrieres td:first-child a").each(function() {
          $(this).attr("data-toggle","modal");
          $(this).attr("data-target","#ajaxmodal");
          $(this).attr("data-remote","false");
        });
$(".post-link").hover(
  function() {
    $(this).parent().find('.caption').addClass( 'hover' );
  }, function() {
   $(this).parent().find('.caption').removeClass('hover');
  }
);
        $(".post-link").click(function(){
          var post_link = $(this).attr("data-url");
          $("#ajaxmodal").attr("data-permalink", post_link);
setTimeout(function(){
          var owloptions={
          nav:true,
          dots:false,
          items:1,
          loop:true,
          autoplay:true,
          autoplayTimeout:6000,
          lazyLoad: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          navText:['<span class="arrow-left"></span>','<span class="arrow-right"></span>']
        };

        $('.jumbotron .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions);
        });
            $('.modal.in').trigger("scroll");  
          }, 3000);




        });
        $(".post-row.post-type-carrieres td:first-child a").click(function(){
          var post_link = $(this).attr("href");
          $("#ajaxmodal").attr("data-permalink", post_link);
        });

        var $this = $("#ajaxmodal");


//close modals with back btn
$this.on('show.bs.modal', function (e) {
    window.history.pushState('forward', null, '#ajaxmodal');
});


$(window).on('popstate', function () {
    $this.modal('hide');
});


        $this.on('shown.bs.modal', function () {
          var permalink =  $(this).attr("data-permalink")+" #ajaxsection";
          $this.find('.ajaxcontent').html(loader);
          $this.find('.ajaxcontent').load(permalink);
          return false;
        });
        $this.on('hide.bs.modal', function(e) {
          $this.attr("data-permalink",'');
          $this.find('.ajaxcontent').html('');
          history.pushState('', document.title, window.location.pathname);

        });
        
        
$(document).ajaxComplete(function() {
 // console.log('complete');
        $(".modal div.lazy").lazyload({
          effect : "fadeIn"
        });
          $(".modal img.lazy").lazyload({
          effect : "fadeIn"
        });





        $(".remote-filter").click(function(){
          var post_filter = $(this).attr("data-target");
          var row_filter = $('[data-name="' + post_filter + '"]').attr('value');
          $("#SelectFonctions").val(row_filter);
          $('#myTab li a').eq($("#SelectFonctions").val()).tab('show');
          $('#ajaxmodal').modal('toggle');
         $('html, body').animate({
      scrollTop: $("#SelectFonctions").offset().top - 125
    }, 800);
       });
        setTimeout(function(){
          $(window).trigger("scroll");
var $iframe = $(".gfiframe").contents();
$iframe.find("#input_4_6").val($('#title').html());
}, 1000);     
      });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var owloptions={
          nav:true,
          dots:false,
          items:1,
          loop:true,
          autoplay:true,
          autoplayTimeout:6000,
          lazyLoad: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          navText:['<span class="arrow-left"></span>','<span class="arrow-right"></span>']
        };

        $('.jumbotron .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions);
        });


$(window).load(function(){

          var post_link = $(".post-link").attr("data-url");
          $("#ajaxmodal").attr("data-permalink", post_link);
});


setTimeout(function(){
  $('#ajaxmodal').modal({show:true});
}, 1000);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'realisations': {
      init: function() {
var hashID = "." + window.location.hash.substring(1);
// initiating the isotope page
if (hashID.length < 2){
 hashID = "";
}
$(window).load(function(){
$this=$('.button[data-filter="' + hashID + '"]');
// Store # parameter and add "." before hash
if (hashID.length > 1) {   
  // ISOTOPE + MENU UPDATE
$this.parent().parent().find('.tax').removeClass('is-checked');
$this.addClass('is-checked');
}      
});

var $grid = $('.grid').isotope({
        itemSelector : '.item',
        layoutMode : 'masonry',
        masonry: {
          columnWidth: '.item',
        },
        filter: hashID,
      });

       var $filterButtons = $('.filters .button');
       
       $grid.on('layoutComplete', function(){
        $(window).trigger("scroll");
      });


       function updateFilterCounts()  {
  // get filtered item elements
  var itemElems = $grid.isotope('getFilteredItemElements');
  var $itemElems = $( itemElems );
  $filterButtons.each( function( i, button ) {
    var $button = $( button );
    var filterValue = $button.attr('data-filter');
    if ( !filterValue ) {
      // do not update 'any' buttons
      return;
    }
    var count = $itemElems.filter( filterValue ).length;
    if (count===0) {
     //  $button.css("display","none");
     $button.find('.filter-count').text( ' (' + count +')' );
    }else{
      // $button.css("display","block");
      $button.find('.filter-count').text( ' (' + count +')' );
    }
  });
}

// flatten object by concatting values
function concatValues( obj ) {
  var value = '';
  for ( var prop in obj ) {
    value += obj[ prop ];
  }
  return value;
}

updateFilterCounts();
// store filter for each group
var filters = {};

$('.filters').on( 'click', '.button', function() {
  $('.modal.in').modal('toggle');
  var $this = $(this);
  // get group key
  var $buttonGroup = $this.parents('.button-group');
  var filterGroup = $buttonGroup.attr('data-filter-group');
  // set filter for group
  filters[ filterGroup ] = $this.attr('data-filter');
  // combine filters
  var filterValue = concatValues( filters );
  // set filter for Isotope
  $('.grid').isotope({ filter: filterValue });
  updateFilterCounts();
});

// change is-checked class on buttons
$('.button-group').each( function( i, buttonGroup ) {
  var $buttonGroup = $( buttonGroup );
  $buttonGroup.on( 'click', '.button', function() {
    $buttonGroup.find('.is-checked').removeClass('is-checked');
    $( this ).addClass('is-checked');
    history.pushState('', document.title, window.location.pathname);
  });
});

 var $anyButtons = $('.filters').find('.button[data-filter=""]');
 // var $buttons = $('.filters').find('button.is-checked.filter');

  $('.button--reset').on( 'click', function() {
    // reset filters
    filters = {};
    $grid.isotope({ filter: '*' });
    // reset buttons
    $('.filters').find('button.is-checked.filter').removeClass('is-checked');
    $anyButtons.addClass('is-checked');
      updateFilterCounts();
  });



}
},
 'entreprises': {
      init: function() {
        // JavaScript to be fired on the about us page
// Add scrollspy to <body>
$('body').scrollspy({target: ".navbar"});

// Add smooth scrolling on all links inside the navbar
$(".btn-anchor-e").on('click', function(event) {

  // Make sure this.hash has a value before overriding default behavior
  if (this.hash !== "") {

    // Prevent default anchor click behavior
    event.preventDefault();

    // Store hash
    var hash = this.hash;

    // Using jQuery's animate() method to add smooth page scroll
    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    $('html, body').animate({
      scrollTop: $(hash).offset().top
    }, 800, function(){

    // Add hash (#) to URL when done scrolling (default click behavior)
      window.location.hash = hash;
    });

  } // End if

});
var $grid = $('.grid').isotope({
        itemSelector : '.item',
        layoutMode : 'masonry',
        masonry: {
          columnWidth: '.item',
        }
      });

$grid.on('shown.bs.collapse hidden.bs.collapse', function() {
  $grid.isotope('layout');
});

$(".refplus").click(function() {
     $(this).find('p').html($(this).find('p').html() === 'MOINS DE RÉFÉRENCES' ? 'PLUS DE RÉFÉRENCES' : 'MOINS DE RÉFÉRENCES');
      $(this).find('img').toggleClass("rotate");
});

      }
    },
 'carrieres': {
      init: function() {        
$('body').scrollspy({target: ".navbar"});
// Add smooth scrolling on all links inside the navbar
$(".btn-anchor-c").on('click', function(event) {

  // Make sure this.hash has a value before overriding default behavior
  if (this.hash !== "") {

    // Prevent default anchor click behavior
    event.preventDefault();

    // Store hash
    var hash = this.hash;

    // Using jQuery's animate() method to add smooth page scroll
    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    $('html, body').animate({
      scrollTop: $(hash).offset().top
    }, 800, function(){

    // Add hash (#) to URL when done scrolling (default click behavior)
      window.location.hash = hash;
    });

  } // End if

});

var owloptions={
          nav:true,
          dots:false,
          items:1,
          loop:true,
          autoplay:true,
          autoplayTimeout:6000,
          lazyLoad: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          navText:['<span class="arrow-left"></span>','<span class="arrow-right"></span>']
        };
var owloptions2={
          nav:false,
          dots:false,
          margin:10,
          slideBy:3,
          responsive:{
        0:{
            items:1
        },
        768:{
            items:2
        },
        960:{
            items:3
        }
    },
          loop:true,
          autoplay:true,
          autoplayTimeout:6000,
          lazyLoad: true,
        };
var owloptions3={
          nav:false,
          dots:true,
          items:1,
          loop:true,
          autoplay:true,
          autoplayTimeout:24000,
          lazyLoad: true,
          lazyLoadEager:3,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn'
        };
var owloptions4={
          nav:false,
          dots:false,
          items:1,
          loop:true,
          autoplay:true,
          autoplayTimeout:6000,
          lazyLoad: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn'
        };
        $('#fonctions .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions);
        });
        $('#valeurs .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions3);
        });
        $('#chiffres .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions2);
        });
   $('#gallery_head .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions4);
        });

      }
    },
    'contact': {
      init: function() {
        (function($) {

function add_marker( $marker, map ) {
  var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
  var marker = new google.maps.Marker({
    position  : latlng,
    map     : map
  });
  map.markers.push( marker );
  if( $marker.html() )
  {
    var infowindow = new google.maps.InfoWindow({
      content   : $marker.html()
    });
    google.maps.event.addListener(marker, 'click', function() {
      infowindow.open( map, marker );
    });
  }
}
function center_map( map ) {
  var bounds = new google.maps.LatLngBounds();
  $.each( map.markers, function( i, marker ){
    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
    bounds.extend( latlng );
  });
  if( map.markers.length === 1 )
  {
      map.setCenter( bounds.getCenter() );
      map.setZoom( 16 );
      

  }
  else
  {
    map.fitBounds( bounds );
  }
}

function new_map( $el ) {
  var $markers = $el.find('.marker');
  var args = {
    zoom    : 16,
    center    : new google.maps.LatLng(0, 0),
    mapTypeId : google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
          zoomControl: true,
          zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_CENTER
          }
  };         
  var map = new google.maps.Map( $el[0], args);
  map.markers = [];
  $markers.each(function(){
      add_marker( $(this), map );
  });
  center_map( map );
  return map;
}
var map = null;
$(document).ready(function(){
  $('.acf-map').each(function(){
    map = new_map( $(this) );
  });
});




})(jQuery);















      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
